import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
const _adminsService = new AdminsService();

/**
 * @param loginPayload
 */
function* listEtatDocumentsSaga(loginPayload) {
     const url ='admin/etat-documents'
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.etatDocumentsListSuccess(response?.data?.etat_documents));
    } catch (error) {
        const { message } = error.data
    console.log("new error" , message);
        yield put(constants.etatDocumentsListFailed(error));
        yield call(toast.error(message))
    }
}

export default function* etatDocumentsSaga() {
    yield takeEvery(Actions.ETAT_DOCUMENTS_LIST_REQUESTING, listEtatDocumentsSaga);
}
