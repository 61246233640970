// export const DOMICILIATION = [
//     {
//         name: "denomination",
//         question: "Quel est le nom de l’entreprise que vous domicilier ?",
//         description: "",
//         type: "simple", //"mutiple",
//         options: [
//           // {value: "", price: ""}
//         ],
//       },
// ]

export const DOMICILIATION = [
  {
    name: "denomination",
    question: "Quel est le nom de l’entreprise que vous souhaitez domicilier ?",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "localisation-ville",
    question: "Dans quelle commune l’entreprise est-elle située ?",
    description: "Une adresse physique et postale est obligatoire pour l’immatriculation de l’entreprise.",
    type: "mutiple",
    options: [
      { value: "Abidjan", price: "" },
      { value: "Hors d'Abidjan", price: "" },
    ],
  },
  {
    name: "localisation-siege",
    question: "Quelle est l’adresse exacte du siège de l’entreprise ?",
    description: "Une adresse physique et postale est requise pour la société.",
    type: "mutiple",
    options: [
      { value: "À mon domicile", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas de siège", price: "" },
    ],
  },
  {
    name: "gestion-domiciliation",
    question: "Souhaitez-vous domicilier votre entreprise chez Legafrik ?",
    description: "Veuillez choisir une formule de domiciliation.",
    type: "mutiple",
    options: [
      { value: "6 mois à 180 000 FCFA (30 000 F/mois)", price: 180_000 },
      { value: "12 mois à 300 000 FCFA (25 000 F/mois)", price: 300_000 },
    ],
  },
];
