import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
const _adminsService = new AdminsService();
/**
 * @param loginPayload
 */
function* listEtapeTraitementsSaga(loginPayload) {
     const url ='admin/etape-traitements'
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.etapeTraitementsListSuccess(response?.data?.etape_traitements));
    } catch (error) {
        const { message } = error.data
    console.log("new error" , message);
        yield put(constants.etapeTraitementsListFailed(error));
        yield call(toast.error(message))
    }
}

function* etapeTraitementsEtatSaga(loginPayload) {
    const {payload}=loginPayload
    const url =`admin/etape-traitements/${payload}/etat-etape-traitements`
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.etapeTraitementsEtatListSuccess(response?.data?.etat_etape_traitements));
    } catch (error) {
        const { message } = error.data
    console.log("new error" , message);
        yield put(constants.etapeTraitementsEtatListFailed(error));
        yield call(toast.error(message))
    }
}

function* etapeTraitementsDetailSaga(loginPayload) {
    const {payload}=loginPayload
    const url =`admin/etape-traitements/${payload}`
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.etapeTraitementsDetailSuccess(response?.data?.etape_traiement));
    } catch (error) {
        const { message } = error.data
    console.log("new error" , message);
        yield put(constants.etapeTraitementsDetailFailed(error));
        yield call(toast.error(message))
    }
}

export default function* etapeTraitementsSaga() {
    yield takeEvery(Actions.ETAPE_TRAITEMENTS_LIST_REQUESTING, listEtapeTraitementsSaga);
    yield takeEvery(Actions.ETAPE_TRAITEMENTS_ETAT_LIST_REQUESTING, etapeTraitementsEtatSaga);
    yield takeEvery(Actions.ETAPE_TRAITEMENTS_DETAIL_REQUESTING, etapeTraitementsDetailSaga);
}
