import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
const _adminsService = new AdminsService();

/**
 * @param loginPayload
 */
function* listTypeDocumentsSaga(loginPayload) {
     const url ='admin/type-documents'
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.typeDocumentsListSuccess(response?.data?.type_documents));
    } catch (error) {
        const { message } = error.data
    console.log("new error" , message);
        yield put(constants.typeDocumentsListFailed(error));
        yield call(toast.error(message))
    }
}

function* documentsByTypeDemandsSaga(loginPayload) {
    const {payload}=loginPayload
    const url =`admin/type-demandes/${payload.type_demande_id}/type-documents`
    try {

        const response = yield call(_adminsService.geList,url);
        yield put(constants.documentsByTypeDemandsSuccess(response?.data?.type_documents));
    } catch (error) {
        const { message } = error.data
    console.log("new error" , message);
        yield put(constants.documentsByTypeDemandsFailed(error));
        yield call(toast.error(message))
    }
}

export default function* typeDocumentsSaga() {
    yield takeEvery(Actions.TYPE_DOCUMENTS_LIST_REQUESTING, listTypeDocumentsSaga);
    yield takeEvery(Actions.DOCUMENTS_BY_TYPE_DEMANDS_REQUESTING, documentsByTypeDemandsSaga);
}
