import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
const _adminsService = new AdminsService();

/**
 * @param loginPayload
 */
function* listRolesSaga(loginPayload) {
     //const {payload}=loginPayload
     const url ='admin/roles'
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.rolesListSuccess(response?.data?.roles));
    } catch (error) {
        const { message } = error.data
    console.log("new error" , message);
        yield put(constants.rolesListFailed(error));
        yield call(toast.error(message))
    }
}


export default function* rolesSaga() {
    yield takeEvery(Actions.ROLES_LIST_REQUESTING, listRolesSaga);
}
