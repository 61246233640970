// export const SAS = [
//   {
//     name: "nom",
//     question: "Quel est le nom du client ?",
//     description: "",
//     type: "simple",
//     options: [
//       // {value: "Veuillez saisir votre nom", price: ""},
//     ],
//   },
//   {
//     name: "prenom",
//     question: "Quel est le prénom(s) du client ?",
//     description: "",
//     type: "simple",
//     options: [
//       // {value: "Veuillez saisir votre prénom(s)", price: ""},
//     ],
//   },
//   {
//     name: "numero-telephone",
//     question: "Quel est son numéro téléphonique (Whatsapp) ?",
//     description: "",
//     type: "simple",
//     options: [
//       // {value: "Veuillez saisir votre numéro de téléphone (Whatsapp) ", price: ""},
//     ],
//   },
//   {
//     name: "email",
//     question: "Quelle est son adresse mail ?",
//     description: "",
//     type: "simple",
//     options: [
//       // {value: "Veuillez saisir votre adresse mail", price: ""},
//     ],
//   },
//   {
//     name: "denomination",
//     question: "Quel est le nom de la société ?",
//     description: "",
//     type: "simple", //"mutiple",
//     options: [
//       // {value: "", price: ""}
//     ],
//   },
//   {
//     name: "nombre-associes",
//     question: "Combien d’associés êtes-vous ?",
//     description: "",
//     type: "simple", //"mutiple",
//     options: [
//       // {value: "", price: ""}
//     ],
//   },
//   {
//     name: "banque",
//     question: "Quelle est la banque de la société ?",
//     description: "",
//     type: "simple", //"mutiple",
//     options: [
//       // {value: "", price: ""}
//     ],
//   },
//   {
//     name: "localisation-ville",
//     question: "Dans quelle commune se situe la société ?",
//     description:
//       "Il faudra impérativement une adresse physique et postale pour la société.",
//     type: "mutiple", //"mutiple",
//     options: [
//       { value: "Abidjan", price: "" },
//       { value: "Hors d'Abidjan", price: "" },
//     ],
//   },
//   {
//     name: "localisation-siege",
//     question: "Quelle est la localisation de la société ?",
//     description:
//         "Il faudra impérativement une adresse physique et postale pour la société.",
//     type: "mutiple", //"mutiple",
//     options: [
//       { value: "A mon domicile", price: "" },
//       // { value: "Je domicilie à Legafrik (30 000 FCFA/mois)", price: "" },
//       { value: "Je loue un local commercial", price: "" },
//       { value: "Je n'ai pas de siège", price: "" },
//     ],
//   },
//   {
//     name: "gestion-domiciliation",
//     question: "Vous avez choisi de domicilier la société chez Legafrik?",
//     description: "Choisissez une formule",
//     type: "mutiple", //"mutiple",
//     options: [
//       { value: "6 mois à 180 000 FCFA (30 000 F/MOIS)", price: 180_000 },
//       { value: "12 mois à 300 000 FCFA (25 000 F/MOIS)", price: 300_000 },
//       { value: "Je ne suis pas intéressé", price: "" },
//     ],
//   },
//   {
//     name: "capital-social",
//     question: "Quel est le capital social de la société ?",
//     description:
//       "Pas de capital minimum exigé pour une SARL ou une SAS en Côte d'Ivoire",
//     type: "mutiple", //"mutiple",
//     options: [
//       { value: "100 000 FCFA", price: "" },
//       { value: "1 000 000 FCFA", price: "" },
//       { value: "100 000 000 FCFA", price: "" },
//       { value: "Autres", price: "" },
//     ],
//   },
// ];

export const SAS = [
  {
    name: "nom",
    question: "Nom du client",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "prenom",
    question: "Prénom(s) du client",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "numero-telephone",
    question: "Saisissez le numéro de téléphone (WhatsApp) du client",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "email",
    question: "Saisissez l'e-mail du client",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "denomination",
    question: "Entrez le nom de l'entreprise",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "nombre-associes",
    question: "Le nombre des associés",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "banque",
    question: "Sélectionnez la banque de la société ",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "localisation-ville",
    question: "La commune/ville où se situe l'entreprise du client",
    description: "Une adresse physique et postale est obligatoire pour l'immatriculation de la société.",
    type: "mutiple",
    options: [
      { value: "Abidjan", price: "" },
      { value: "Hors d'Abidjan", price: "" },
    ],
  },
  {
    name: "localisation-siege",
    question: "Le siège social de l'entreprise du client (localisation)",
    description: "L'adresse du siège social est requise pour les correspondances officielles.",
    type: "mutiple",
    options: [
      { value: "À mon domicile", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas encore de siège social", price: "" },
    ],
  },
  {
    name: "gestion-domiciliation",
    question: "Souhaitez-vous domicilier votre société chez Legafrik ?",
    description: "Sélectionnez une option de domiciliation adaptée à vos besoins.",
    type: "mutiple",
    options: [
      { value: "6 mois à 180 000 FCFA (30 000 F/mois)", price: 180_000 },
      { value: "12 mois à 300 000 FCFA (25 000 F/mois)", price: 300_000 },
      { value: "Je ne suis pas intéressé", price: "" },
    ],
  },
  {
    name: "capital-social",
    question: "Le montant du capital social de l'entreprise ",
    description: "Il n'y a pas de capital minimum requis pour une SAS en Côte d'Ivoire.",
    type: "mutiple",
    options: [
      { value: "100 000 FCFA", price: "" },
      { value: "1 000 000 FCFA", price: "" },
      { value: "100 000 000 FCFA", price: "" },
      { value: "Autres", price: "" },
    ],
  },
];
