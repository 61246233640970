//   export const ASSOCIATION = [
//    {
//         name: "nom",
//         question: "Quel est le nom du client ?",
//         description: "",
//         type: "simple",
//         options: [
//             // {value: "Veuillez saisir votre nom", price: ""},
//         ],
//     },
//     {
//         name: "prenom",
//         question: "Quel est le prénom(s) du client ?",
//         description: "",
//         type: "simple",
//         options: [
//             // {value: "Veuillez saisir votre prénom(s)", price: ""},
//         ],
//   },
//   {
//         name: "numero-telephone",
//         question: "Quel est son numéro téléphonique (Whatsapp) ?",
//         description: "",
//         type: "simple",
//         options: [
//             // {value: "Veuillez saisir votre numéro de téléphone (Whatsapp) ", price: ""},
//         ],
//     },
//   {
//         name: "email",
//         question: "Quelle est son adresse mail ?",
//         description: "",
//         type: "simple",
//         options: [
//             // {value: "Veuillez saisir votre adresse mail", price: ""},
//         ],
//     },
//   {
//     name: "denomination",
//     question: "Quel est le nom de l'association ?",
//     description: "",
//     type: "simple", //"mutiple",
//     options: [
//       // {value: "", price: ""}
//     ],
//   },
//   {
//     name: "localisation-ville",
//     question: "Dans quelle commune se situe l'association ?",
//     description:
//         "Il faudra impérativement une adresse physique et postale pour l'association.",
//     type: "mutiple", //"mutiple",
//     options: [
//       { value: "Abidjan", price: "" },
//       { value: "Hors d'Abidjan", price: "" },

//     ],
//   },
//   {
//     name: "localisation-siege",
//     question: "Quelle est la localisation de l'association ?",
//     description: "Il faudra impérativement une adresse physique et postale pour l'association.",
//     type: "mutiple", //"mutiple",
//     options : [
//         { value: "A mon domicile", price: "" },
//         { value: "Je loue un local commercial", price: "" },
//         { value: "Je n'ai pas de siège", price: "" },
//     ]
//   },
//   {
//         name: "gestion-domiciliation",
//         question: "Vous avez choisi de domicilier l'association chez Legafrik?",
//         description: "Choisissez une formule",
//         type: "mutiple", //"mutiple",
//         options : [
//             {value: "6 mois à 180 000 FCFA(30 000 F/MOIS)", price: 180_000},
//             {value: "12 mois à 300 000 FCFA (25 000 F/MOIS)", price: 300_000},
//         ]
//   }
// ];

export const ASSOCIATION = [
  {
    name: "nom",
    question: "Veuillez indiquer le nom de famille du client.",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "prenom",
    question: "Veuillez indiquer le(s) prénom(s) du client.",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "numero-telephone",
    question: "Quel est le numéro de téléphone principal du client (de préférence WhatsApp) ?",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "email",
    question: "Quelle est l'adresse e-mail du client ?",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "denomination",
    question: "Quel est le nom de l'association ?",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "localisation-ville",
    question: "Dans quelle commune se situe l'association ?",
    description: "Une adresse physique et postale est obligatoire pour l'association.",
    type: "mutiple",
    options: [
      { value: "Abidjan", price: "" },
      { value: "Hors d'Abidjan", price: "" },
    ],
  },
  {
    name: "localisation-siege",
    question: "Quelle est l’adresse exacte du siège de l'association ?",
    description: "Une adresse physique et postale est requise pour l'association.",
    type: "mutiple",
    options: [
      { value: "À mon domicile", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas de siège", price: "" },
    ],
  },
  {
    name: "gestion-domiciliation",
    question: "Souhaitez-vous domicilier votre association chez Legafrik ?",
    description: "Veuillez choisir une formule.",
    type: "mutiple",
    options: [
      { value: "6 mois à 180 000 FCFA (30 000 F/mois)", price: 180_000 },
      { value: "12 mois à 300 000 FCFA (25 000 F/mois)", price: 300_000 },
    ],
  },
];
