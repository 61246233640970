// export const DEPOT_MARQUE = [
//     {
//         name: "protection",
//         question: "Que souhaitez-vous protéger ?",
//         description: "",
//         type: "simple", //"mutiple",
//         options : [
//             // {value: "", price: ""}
//         ]
//     },
//     {
//         name: "nom-marque",
//         question: "Quel nom envisagez-vous de déposer ?",
//         description: "",
//         type: "simple", //"mutiple",
//         options : [
//             // {value: "", price: ""}
//         ]
//     },
//     {
//         name: "secteur-activite",
//         question: "Dans quel domaine d'activité souhaitez-vous exploiter cette marque ?",
//         description: "",
//         type: "mutiple", //"mutiple",
//         options : [
//             {value: "BTP", price: ""},
//             {value: "Evènementiel", price: ""},
//             {value: "Informatiques", price: ""},
//             {value: "Finance", price: ""},
//             {value: "Formation", price: ""},
//             {value: "Autres", price: ""},
        
//         ]
//     },
//     {
//         name: "cabinet-formation",
//         question: "Souhaitez vous être accompagné par Legafrik pour obtenir votre agrément FDFP ?",
//         description: "",
//         type: "mutiple", //"mutiple",
//         options: [
//           { value: "Je me fais accompagner pour obtenir l'agrément FDFP (100 000 FCFA)", price: 100_000 },
//           { value: "Je ne suis pas Intéressé ", price: "" },
//         ],
//       },
//     {
//         name: "proprietaire",
//         question: "Qui sera le propriétaire de la marque ?",
//         description: "",
//         type: "mutiple", //"mutiple",
//         options : [
//             {value: "Moi-même", price: ""},
//             {value: "Mon entreprise", price: ""},
//             {value: "Une autre personne", price: ""}
//         ]
//     }
//     ]

export const DEPOT_MARQUE = [
  {
    name: "protection",
    question: "Quel élément souhaitez-vous protéger avec votre marque ?",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "nom-marque",
    question: "Quel nom souhaitez-vous déposer pour votre marque ?",
    description: "",
    type: "simple",
    options: [],
  },
  {
    name: "secteur-activite",
    question: "Dans quel secteur d'activité allez-vous exploiter cette marque ?",
    description: "",
    type: "mutiple",
    options: [
      { value: "BTP", price: "" },
      { value: "Événementiel", price: "" },
      { value: "Informatique", price: "" },
      { value: "Finance", price: "" },
      { value: "Formation", price: "" },
      { value: "Autres", price: "" },
    ],
  },
  {
    name: "cabinet-formation",
    question: "Souhaitez-vous être accompagné par Legafrik pour obtenir votre agrément FDFP ?",
    description: "",
    type: "mutiple",
    options: [
      { value: "Je souhaite être accompagné pour obtenir l'agrément FDFP (100 000 FCFA)", price: 100_000 },
      { value: "Je ne suis pas intéressé", price: "" },
    ],
  },
  {
    name: "proprietaire",
    question: "Qui sera le propriétaire de la marque ?",
    description: "",
    type: "mutiple",
    options: [
      { value: "Moi-même", price: "" },
      { value: "Mon entreprise", price: "" },
      { value: "Une autre personne", price: "" },
    ],
  },
];
